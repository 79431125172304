import React, { useState, useEffect } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  CircularProgress,
  Button
} from '@mui/material'; // Import Material-UI components
import { styled } from '@mui/system'; // Import styled from @mui/system
import instance from './axios';

const StyledTableContainer = styled(TableContainer)({
  maxWidth: '100%',
  marginTop: '20px',
  overflowX: 'auto'
});

function Home() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchUsers = async () => {
    try {
      const response = await instance.get('/admin-users'); // Replace with your API endpoint
      setUsers(response.data); // Assuming your API response is an array of users
      setLoading(false);
    } catch (error) {
      console.error('Error fetching users:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleEnableDisable = async (id) => {
    try {
      await instance.get(`/admin-users/${id}/status`, ); 
      alert("user updated successfully")
      fetchUsers(); 
    } catch (error) {
        alert('something went wrong')
      console.error('Error updating user status:', error);
    }
  };

  return (
    <div>
      <h2>Users List</h2>
      {loading ? (
        <CircularProgress />
      ) : (
        <StyledTableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
              <TableCell>Company ID</TableCell>
              <TableCell>Company Name</TableCell>
                <TableCell>Admin Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>Actions</TableCell> {/* Add actions header */}
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user.id}>
                <TableCell>{user.id}</TableCell>
                 <TableCell>{user.companyName}</TableCell>
                  <TableCell>{user.adminName}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.phoneNumber}</TableCell>
                  <TableCell>
                    {user.isEnabled==true? (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleEnableDisable(user.id, false)}
                      >
                        Disable
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleEnableDisable(user.id, true)}
                      >
                        Enable
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      )}
    </div>
  );
}

export default Home;
