// src/axiosConfig.js
import axios from 'axios';

// Create an Axios instance
const instance = axios.create({
  baseURL: 'https://api.projecttrackai.com/', // Replace with your base URL
  timeout: 10000, // Timeout after 10 seconds
  headers: {
    'Content-Type': 'application/json',
    // Add any other headers you need
  }
});

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    // Get the token from local storage
    const token = localStorage.getItem('token');

    // If token exists, set the Authorization header
    if (token) {
      config.headers.Authorization = token
    }

    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Check if the error response status is 401 (Unauthorized)
    if (error.response && error.response.data.message=="Failed to authenticate token"||error.response.data.message == "No token provided" ) {
      // Perform logout operation
      localStorage.removeItem('token');
      // Redirect to login page or handle logout logic
      window.location.href = '/login'; // Replace with your login route
    }

    return Promise.reject(error);
  }
);

export default instance;
