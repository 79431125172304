import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import instance from './axios';

const validationSchema = Yup.object({
  companyName: Yup.string()
    .max(50, 'Company Name must be 50 characters or less')
    .required('Company Name is required'),
  companyAddress: Yup.string()
    .max(75, 'Company Address must be 75 characters or less')
    .required('Company Address is required'),
  adminName: Yup.string()
    .max(25, 'Administrator Name must be 15 characters or less')
    .required('Administrator Name is required'),
  phoneNumber: Yup.string()
    .matches(/^\d{10}$/, 'Phone number must be 10 digits')
    .required('Phone Number is required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  fax: Yup.string(),
  password: Yup.string()
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
  plan: Yup.string().required('Plan is required')
});

const RegisterForm = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false); // State for loading indicator

  const handleLogout = () => {
    localStorage.clear();
    navigate('/login');
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setIsSubmitting(true); // Start loading state
    try {
      const payload = {
        companyName: values.companyName,
        companyAddress: values.companyAddress,
        adminName: values.adminName,
        phoneNumber: values.phoneNumber,
        email: values.email,
        fax: values.fax,
        password: values.password,
        plan: values.plan
      };

      const response = await instance.post('/create-admins', payload);
      console.log('API Response:', response.data);
      alert("user created succesfully")
      // Handle success, navigate or show success message
      // Example: navigate('/success');

    } catch (error) {
      console.error('API Error:', error.response.data.message);
      alert(error.response.data.message)
      // Handle error, show error message
    } finally {
      setIsSubmitting(false); // Reset loading state
      setSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="flex justify-between p-4 bg-white shadow-md">
        <h1 className="text-2xl font-bold">Create New Admin User</h1>
        
      </div>
      <div className="flex items-center justify-center">
        <div className="w-full max-w-4xl p-8 space-y-6 bg-white rounded-lg shadow-md mt-4">
          <Formik
            initialValues={{
              companyName: '',
              companyAddress: '',
              adminName: '',
              phoneNumber: '',
              email: '',
              fax: '',
              password: '',
              confirmPassword: '',
              plan: ''
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form className="space-y-4">
                <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Company Name</label>
                    <Field name="companyName" type="text" className="block w-full px-3 py-2 mt-1 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                    <ErrorMessage name="companyName" component="div" className="mt-1 text-sm text-red-600" />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Company Address</label>
                    <Field name="companyAddress" type="text" className="block w-full px-3 py-2 mt-1 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                    <ErrorMessage name="companyAddress" component="div" className="mt-1 text-sm text-red-600" />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Administrator Name</label>
                    <Field name="adminName" type="text" className="block w-full px-3 py-2 mt-1 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                    <ErrorMessage name="adminName" component="div" className="mt-1 text-sm text-red-600" />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Phone Number</label>
                    <Field name="phoneNumber" type="text" className="block w-full px-3 py-2 mt-1 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                    <ErrorMessage name="phoneNumber" component="div" className="mt-1 text-sm text-red-600" />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Email</label>
                    <Field name="email" type="email" className="block w-full px-3 py-2 mt-1 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                    <ErrorMessage name="email" component="div" className="mt-1 text-sm text-red-600" />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Fax</label>
                    <Field name="fax" type="text" className="block w-full px-3 py-2 mt-1 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                    <ErrorMessage name="fax" component="div" className="mt-1 text-sm text-red-600" />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Password</label>
                    <Field name="password" type="password" className="block w-full px-3 py-2 mt-1 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                    <ErrorMessage name="password" component="div" className="mt-1 text-sm text-red-600" />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Confirm Password</label>
                    <Field name="confirmPassword" type="password" className="block w-full px-3 py-2 mt-1 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                    <ErrorMessage name="confirmPassword" component="div" className="mt-1 text-sm text-red-600" />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Plan</label>
                    <Field name="plan" as="select" className="block w-full px-3 py-2 mt-1 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                      <option value="">Select a Plan</option>
                      <option value="silver">Silver</option>
                      <option value="platinum">Platinum</option>
                      <option value="gold">Gold</option>
                    </Field>
                    <ErrorMessage name="plan" component="div" className="mt-1 text-sm text-red-600" />
                  </div>
                </div>
                <div>
                  <button type="submit" className="block w-full px-4 py-2 font-medium text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" disabled={isSubmitting}>
                    {isSubmitting ? 'Submitting...' : 'Submit'}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default RegisterForm;
