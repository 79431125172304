// src/components/Layout.js
import React from 'react';
import Sidebar from './sidebar/index';
import { Outlet } from 'react-router-dom';

const Layout = () => {
  return (
    <div style={{ display: 'flex' }}>
      <Sidebar />
      <main style={{ flexGrow: 1, padding: '16px' }}>
        <Outlet />
      </main>
    </div>
  );
};

export default Layout;
